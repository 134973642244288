<template>
  <v-container class="fill-height" fluid data-cy="impersonate-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <Impersonate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Impersonate from "@/modules/auth/components/Impersonate.vue";

export default {
  name: "ImpersonatePage",
  components: {
    Impersonate,
  },
};
</script>
