<template>
  <v-card data-cy="impersonate-comp">
    <v-card-text>
      <div>{{ $t("auth.impersonations") }}</div>
      <v-list>
        <v-list-item two-line>
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ organisationName }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ userName }} {{ userSurnames }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              color="primary"
              @click.prevent="clickContinue"
              data-cy="btn-impersonate-self"
            >
              <v-icon>$impersonation</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="showSearch">
          <v-text-field
            v-model="editSearch"
            outlined
            clearable
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-list-item>
        <v-divider v-else inset></v-divider>

        <v-virtual-scroll
          :items="impersonationsFromFiltered"
          :height="scrollHeight"
          :item-height="64"
        >
          <template v-slot:default="{ item }">
            <v-list-item two-line>
              <v-list-item-action>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.organisation_name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ item.user_name }}
                  {{ item.user_surnames }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click.prevent="clickImpersonate(item)"
                  data-cy="btn-impersonate"
                >
                  <v-icon>$impersonation</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "Login",
  mixins: [ComponentStatus],
  components: {},
  data: () => ({
    editSearch: null,
    editSearchDecoupled: null,
    editSearchTimer: null,
  }),
  computed: {
    organisationName() {
      return this.$store.getters["auth/getOrganisationName"];
    },
    userName() {
      return this.$store.getters["auth/getUserName"];
    },
    userSurnames() {
      return this.$store.getters["auth/getUserSurnames"];
    },
    impersonationsFrom() {
      return this.$store.getters["auth/getImpersonationsFrom"].map(
        (element) => {
          element.search =
            `${element.organisation_name} ${element.user_name} ${element.user_surname} ${element.user_email}`.toLocaleLowerCase();
          return element;
        }
      );
    },
    showSearch() {
      return this.impersonationsFrom.length > 5;
    },
    impersonationsFromFiltered() {
      return this.impersonationsFrom.filter((element) => {
        if (this.editSearchDecoupled === null) {
          return true;
        } else {
          if (element.search.indexOf(this.editSearchDecoupled) !== -1) {
            return true;
          }
        }
        return false;
      });
    },
    scrollHeight() {
      // TODO: check if we can do this sort of calculations.
      // if (this.$refs && this.$refs.pageContainer) {
      //   return this.$refs.pageContainer.$el.clientHeight - 200;
      // }
      return 500;
    },
  },
  watch: {
    editSearch(newValue) {
      if (this.editSearchTimer !== null) {
        clearTimeout(this.editSearchTimer);
        this.editSearchTimer = null;
      }
      this.editSearchTimer = setTimeout(() => {
        if (newValue === null) {
          this.editSearchDecoupled = null;
        } else {
          this.editSearchDecoupled = newValue.toLocaleLowerCase();
        }
      }, 500);
    },
  },
  methods: {
    clickContinue() {
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect).then(() => {
          location.reload();
        });
      } else {
        this.$router.push({ path: "home" }).then(() => {
          location.reload();
        });
      }
    },
    clickImpersonate(impersonation) {
      this.$store
        .dispatch("auth/impersonateTo", {
          to_user_id: impersonation["to_user_id"],
          to_organisation_id: impersonation["to_organisation_id"],
        })
        .then((/* result */) => {
          this.setStatusReadSuccess();
          if (this.$route.query.redirect) {
            return this.$router.push(this.$route.query.redirect);
          } else {
            return this.$router.push({ name: "home" });
          }
        })
        .then(() => {
          location.reload();
        })
        .catch((/* error */) => {
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
